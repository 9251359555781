$gray-500: #64748B;
$gray-400:#97A6BA;
$foreground: mix($gray-500, $gray-400);;
input,
textarea {
    // background: transparent;

    // Placeholder color
    &::placeholder {
        color: $foreground;
    }

    &::-moz-placeholder {
       color: $foreground;
    }

    &::-webkit-input-placeholder {
       color: $foreground;
    }

    &:-ms-input-placeholder {
       color: $foreground;
    }

    &:-webkit-autofill {
        -webkit-transition: 'background-color 9999s ease-out';
        -webkit-transition-delay: 9999s;
    }

    &:-webkit-autofill:hover {
        -webkit-transition: 'background-color 9999s ease-out';
        -webkit-transition-delay: 9999s;
    }

    &:-webkit-autofill:focus {
        -webkit-transition: 'background-color 9999s ease-out';
        -webkit-transition-delay: 9999s;
    }

    &:-webkit-autofill:active {
        -webkit-transition: 'background-color 9999s ease-out';
        -webkit-transition-delay: 9999s;
    }
}
/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
.mat-button-focus-overlay {
    background-color: transparent !important;
  }
