// Shop Card 
body .mat-mdc-card .mat-mdc-card-image {
	max-width: inherit;
	border-bottom: 1px solid #eee;
	  
}

.m-icon {
	position: absolute;
	top: -49px;
	right: -5px;
	transition: all 0.3s ease-in;
}
.wishlist-icon{
   position: absolute;
	top: 15px;
	right: 010px;
   z-index: 99;
   cursor: pointer;
	width: 50px;
   height: 50px;
   transition: all 0.3s ease-in 0s;
	text-align: center;
	line-height: 50px;
   a {
      color: rgba(0,0,0,0.4);
      &:hover{
         color:rgba(0, 0, 0, 0.7);
      }
   }
  
}

/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
mat-card:hover .m-icon {
	transform: translateY(-20px);
}

.no-card-content {
	padding-bottom: 0 !important;
}

.card-shadow {
	box-shadow: $card-shadow;
}

.shop-card-gap{
   margin-top: -120px;
   padding:1rem;
}
.section-title-toolbar{
   padding:1rem;
}

.section-title-toolbar {
	border-radius: 10px;
}
.box-shadow-md{
   &:hover{
      box-shadow: 0 5px 10px 0 rgb(55 55 55 / 20%);

      .button-grey {
            background: var(--accent-color);
            color: #fff;
          }
   }
}

.wishlist-icon.wishlist-active a{
  color:rgba(0,0,0,1);
}